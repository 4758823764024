export default {
  "logoLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bridge"])},
  "connectWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect Wallet"])},
  "testnetMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the testnet version. Dont send real coins!"])},
  "providers": {
    "metamask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metamask"])},
    "walletConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "walletLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coinbase Wallet"])}
  },
  "networks": {
    "ton": {
      "transactionWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait for the TON transaction to be processed"])},
      "transactionSend": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please send TON transaction in ", _interpolate(_named("provider"))])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toncoin"])},
      "transactionCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TON transaction completed successfully"])},
      "transactionSend1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please send "])},
      "transactionSend2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toncoin to the address"])},
      "transactionSendComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with the comment"])},
      "openWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Wallet"])},
      "generateQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate QR Code"])},
      "scanQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or scan QR code below"])},
      "errors": {
        "invalidAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not valid TON address"])}
      },
      "main": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Open Network"])},
        "nameSwitcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Open\nNetwork"])},
        "nameShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TON"])},
        "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toncoin"])},
        "coinShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toncoin"])}
      },
      "test": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TON Testnet"])},
        "nameSwitcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TON\nTestnet"])},
        "nameShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TON"])},
        "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toncoin"])},
        "coinShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toncoin"])}
      }
    },
    "eth": {
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETH"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TON-ETH Bridge"])},
      "gasFee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ethereum gas fee ~ ", _interpolate(_named("fee")), " ETH"])},
      "transactionCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum transaction completed successfully"])},
      "transactionWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait for the Ethereum transaction to be processed"])},
      "transactionSend": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please send Ethereum transaction in ", _interpolate(_named("provider"))])},
      "blocksConfirmations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Collected ", _interpolate(_named("count")), " Ethereum block confirmations"])},
      "errors": {
        "invalidAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not valid ETH address"])},
        "lowBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to have ETH on your wallet to pay for gas in the Ethereum network"])}
      },
      "main": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum Network"])},
        "nameSwitcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum\nNetwork"])},
        "nameShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETH"])},
        "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN (ERC-20)"])},
        "coinShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN"])}
      },
      "test": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum Goerli"])},
        "nameSwitcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum\nGoerli"])},
        "nameShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETH"])},
        "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN (ERC-20)"])},
        "coinShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN"])}
      }
    },
    "bsc": {
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BNB"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TON-BSC Bridge"])},
      "gasFee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["BSC gas fee ~ ", _interpolate(_named("fee")), " BNB"])},
      "transactionCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSC transaction completed successfully"])},
      "transactionWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait for the BSC transaction to be processed"])},
      "transactionSend": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please send BSC transaction in ", _interpolate(_named("provider"))])},
      "blocksConfirmations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Collected ", _interpolate(_named("count")), " BSC block confirmations"])},
      "errors": {
        "invalidAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not valid BSC address"])},
        "lowBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to have BNB on your wallet to pay for gas in the BSC network"])}
      },
      "main": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binance Smart Chain"])},
        "nameSwitcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binance\nSmart Chain"])},
        "nameShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSC"])},
        "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN (BEP-20)"])},
        "coinShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN"])}
      },
      "test": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSC Testnet"])},
        "nameSwitcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSC\nTestnet"])},
        "nameShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSC"])},
        "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN (BEP-20)"])},
        "coinShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN"])}
      }
    }
  },
  "tokenAddress": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Token address in ", _interpolate(_named("network")), " network"])},
  "sendToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
  "amountOfTon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Amount ", _interpolate(_named("tokenSymbol"))])},
  "addressInputLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Destination address in ", _interpolate(_named("network")), " network"])},
  "willReceive": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You will receive ", _interpolate(_named("fee")), " ", _interpolate(_named("coin"))])},
  "bridgeFeeAbove10": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bridge fee - ", _interpolate(_named("fee")), " TON"])},
  "bridgeFeeBelow10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bridge fee - 5 TON + 0.25% of amount"])},
  "tokenBridgeFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bridge fee - 1 TON"])},
  "tokenBridgeFeeToEvm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bridge fee - 1 TON + 0.1% of token amount"])},
  "sourceCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source Code"])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "howItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])},
  "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
  "transferHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
  "disconnectWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])},
  "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
  "getToncoin": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Get ", _interpolate(_named("coin"))])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "blocksConfirmationsCollected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The confirmations of the blocks are collected"])},
  "blocksConfirmationsWaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait a bit for the blocks confirmations"])},
  "oraclesConfirmations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Collected ", _interpolate(_named("count")), " confirmations of oracles"])},
  "oraclesConfirmationsCollected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The confirmations of the oracles are collected"])},
  "oraclesConfirmationsWaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait a bit for the oracles to confirm the transfer"])},
  "getCoinsByProvider": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Get ", _interpolate(_named("toCoin")), "s by ", _interpolate(_named("provider"))])},
  "coinsSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("toCoin")), "s have been sent"])},
  "getCoins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Get ", _interpolate(_named("toCoin")), "s in ", _interpolate(_named("toNetwork"))])},
  "confirmInWallet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Confirm in ", _interpolate(_named("provider"))])},
  "otherTokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other tokens"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
  "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
  "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruction"])},
  "errors": {
    "alertTitleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "alertButtonClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "notValidAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount is not valid number"])},
    "amountBelow10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount must be at least 10 TON"])},
    "needPersonalAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter YOUR address to receive"])},
    "toncoinBalance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have only ", _interpolate(_named("balance")), " ", _interpolate(_named("coin"))])},
    "installMetamask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please install MetaMask"])},
    "wrongProviderNetwork": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Set chain to ", _interpolate(_named("network")), " in ", _interpolate(_named("provider"))])},
    "cantGetAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't get account address"])},
    "providerIsDisconnected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("provider")), " is disconnected"])}
  },
  "history": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent history"])},
    "recentHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent history"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "amountFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount / From (Network)"])},
    "amountTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Received / To (Network)"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas / Bridge Fee"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completed"])},
    "getToncoin": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Get ", _interpolate(_named("coin"))])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "oracles": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " of oracles"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transfers found"])}
  }
}