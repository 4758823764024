import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10498dc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "readonly", "type", "id", "inputmode"]
const _hoisted_2 = { class: "CustomInput-labelWrapper" }
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "CustomInput-arrow"
}
const _hoisted_5 = {
  key: 1,
  class: "CustomInput-dropdown"
}
const _hoisted_6 = ["onMousedown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["CustomInput", { hasData: _ctx.hasData, disabled: _ctx.disabled, error: !!_ctx.error && _ctx.hasData, isDropdownOpened: _ctx.isDropdownOpened }])
  }, [
    _withDirectives(_createElementVNode("input", {
      disabled: _ctx.disabled,
      readonly: _ctx.hasDropdown,
      type: _ctx.type,
      id: 'CustomInput-' + _ctx.$uuid.v1(),
      inputmode: _ctx.type === 'number' ? 'decimal' : 'text',
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('changed'))),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDropdownOpened = true)),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDropdownOpened = true)),
      onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('blur'))),
      onFocusout: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onInputFocusOut && _ctx.onInputFocusOut(...args))),
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model) = $event))
    }, null, 40, _hoisted_1), [
      [_vModelDynamic, _ctx.model]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        for: 'CustomInput-' + _ctx.$uuid.v1()
      }, _toDisplayString((_ctx.hasData && _ctx.error) || _ctx.label), 9, _hoisted_3)
    ]),
    (_ctx.hasDropdown)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.hasDropdown)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdown, (item, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createElementVNode("button", {
                onMousedown: ($event: any) => (_ctx.onOptionClick(item.value))
              }, _toDisplayString(item.label), 41, _hoisted_6)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}