import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41cc15b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Footer" }
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  href: "https://t.me/ton_help_bot",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createTextVNode(" v3.0.22, "),
    _createElementVNode("a", {
      href: _ctx.instructionLink,
      target: "_blank"
    }, _toDisplayString(_ctx.$t(`instruction`)), 9, _hoisted_2),
    _createTextVNode(", "),
    _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.$t("support")), 1),
    _createTextVNode(" . ")
  ]))
}