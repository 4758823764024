export default {
  "logoLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бридж"])},
  "connectWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить Кошелек"])},
  "testnetMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа в режиме тестовой сети. Не отправляйте реальные монеты!"])},
  "providers": {
    "metamask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metamask"])},
    "walletConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "walletLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coinbase Wallet"])}
  },
  "networks": {
    "ton": {
      "transactionWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, подождите пока обрабатывается транзакция TON"])},
      "transactionSend": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пожалуйста, подтвердите TON транзакцию в ", _interpolate(_named("provider"))])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toncoin"])},
      "transactionCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция TON успешно завершена"])},
      "transactionSend1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, отправьте "])},
      "transactionSend2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toncoin на адрес"])},
      "transactionSendComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["с комментарием"])},
      "openWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть Кошелек"])},
      "generateQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать QR-код"])},
      "scanQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или отсканируйте QR-код ниже"])},
      "errors": {
        "invalidAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не валидный TON адрес"])}
      },
      "main": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Open Network"])},
        "nameSwitcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Open\nNetwork"])},
        "nameShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TON"])},
        "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toncoin"])},
        "coinShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toncoin"])}
      },
      "test": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TON Testnet"])},
        "nameSwitcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TON\nTestnet"])},
        "nameShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TON"])},
        "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toncoin"])},
        "coinShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toncoin"])}
      }
    },
    "eth": {
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETH"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TON-ETH Бридж"])},
      "gasFee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Комиссия Ethereum (газ) ~ ", _interpolate(_named("fee")), " ETH"])},
      "transactionCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция Ethereum успешно завершена"])},
      "transactionWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, подождите пока обрабатывается транзакция Ethereum"])},
      "transactionSend": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пожалуйста, подтвердите Ethereum транзакцию в ", _interpolate(_named("provider"))])},
      "blocksConfirmations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Собрано ", _interpolate(_named("count")), " подтверждений блоков Ethereum"])},
      "errors": {
        "invalidAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не валидный ETH адрес"])},
        "lowBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас должны быть ETH в кошельке чтобы оплатить газ в сети Ethereum"])}
      },
      "main": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum Network"])},
        "nameSwitcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum\nNetwork"])},
        "nameShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETH"])},
        "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN (ERC-20)"])},
        "coinShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN"])}
      },
      "test": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum Goerli"])},
        "nameSwitcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum\nGoerli"])},
        "nameShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETH"])},
        "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN (ERC-20)"])},
        "coinShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN"])}
      }
    },
    "bsc": {
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BNB"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TON-BSC Бридж"])},
      "gasFee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Комиссия BSC (газ) ~ ", _interpolate(_named("fee")), " BNB"])},
      "transactionCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция BSC успешно завершена"])},
      "transactionWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, подождите пока обрабатывается транзакция BSC"])},
      "transactionSend": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пожалуйста, подтвердите BSC транзакцию в ", _interpolate(_named("provider"))])},
      "blocksConfirmations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Собрано ", _interpolate(_named("count")), " подтверждений блоков BSC"])},
      "errors": {
        "invalidAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не валидный BSC адрес"])},
        "lowBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас должны быть BNB в кошельке чтобы оплатить газ в сети BSC"])}
      },
      "main": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binance Smart Chain"])},
        "nameSwitcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binance\nSmart Chain"])},
        "nameShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSC"])},
        "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN (BEP-20)"])},
        "coinShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN"])}
      },
      "test": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSC Testnet"])},
        "nameSwitcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSC\nTestnet"])},
        "nameShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSC"])},
        "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN (BEP-20)"])},
        "coinShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TONCOIN"])}
      }
    }
  },
  "sendToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токен"])},
  "amountOfTon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Количество ", _interpolate(_named("tokenSymbol"))])},
  "tokenAddress": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Адрес токена в сети ", _interpolate(_named("network"))])},
  "addressInputLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Адрес получения в сети ", _interpolate(_named("network"))])},
  "willReceive": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы получите ", _interpolate(_named("fee")), " ", _interpolate(_named("coin"))])},
  "bridgeFeeAbove10": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Комиссия бриджа - ", _interpolate(_named("fee")), " TON"])},
  "bridgeFeeBelow10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия бриджа - 5 TON + 0.25% от суммы"])},
  "tokenBridgeFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия бриджа - 1 TON"])},
  "tokenBridgeFeeToEvm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия бриджа - 1 TON + 0.1% от суммы токенов"])},
  "sourceCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исходный код"])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тех. поддержка"])},
  "howItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как это работает"])},
  "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документация"])},
  "transferHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
  "disconnectWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить"])},
  "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод"])},
  "getToncoin": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Получить ", _interpolate(_named("coin"))])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
  "blocksConfirmationsCollected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собраны подтверждения блоков"])},
  "blocksConfirmationsWaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, подождите подтверждения блоков"])},
  "oraclesConfirmations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Собрано ", _interpolate(_named("count")), " подтверждений оракулов"])},
  "oraclesConfirmationsCollected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собраны подтверждения оракулов"])},
  "oraclesConfirmationsWaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, подождите подтверждения оракулов"])},
  "getCoinsByProvider": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Получите ", _interpolate(_named("toCoin")), " в ", _interpolate(_named("provider"))])},
  "coinsSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("toCoin")), " были отправлены"])},
  "getCoins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Получите ", _interpolate(_named("toCoin")), " в ", _interpolate(_named("toNetwork"))])},
  "confirmInWallet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Подтвердите в ", _interpolate(_named("provider"))])},
  "otherTokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие токены"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["токены"])},
  "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одобрить"])},
  "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструкция"])},
  "errors": {
    "alertTitleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
    "alertButtonClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
    "notValidAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введено не валидное число"])},
    "amountBelow10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество должно быть не менее 10 TON"])},
    "needPersonalAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите СВОЙ адрес для получения"])},
    "toncoinBalance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["У вас всего ", _interpolate(_named("balance")), " ", _interpolate(_named("coin"))])},
    "installMetamask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, установите MetaMask"])},
    "wrongProviderNetwork": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Переключите сеть на ", _interpolate(_named("network")), " в ", _interpolate(_named("provider"))])},
    "cantGetAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не могу получить адрес аккаунта"])},
    "providerIsDisconnected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("provider")), " отключился"])}
  },
  "history": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
    "recentHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "amountFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество / Откуда (сеть)"])},
    "amountTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получено / Куда (сеть)"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Газ / Бридж комиссии"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["завершено"])},
    "getToncoin": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Получить ", _interpolate(_named("coin"))])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить"])},
    "oracles": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " оракулов"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переводы не найдены"])}
  }
}