
import {defineComponent} from "vue";

export default defineComponent({
  name: "Footer",
  computed: {
    instructionLink(): string {
      return this.$t(`instruction`) === 'Инструкция' ? 'https://tonblockchain.ru/chto-takoie-mosty-v-blokchieinie-i-kak-oni-rabotaiut/' : 'https://blog.ton.org/ton-erc-20-bridge-tutorial'
    }
  }
});
