import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-128d0bc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "LayoutDefault" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.alert)
      ? (_openBlock(), _createBlock(_component_Alert, {
          key: 0,
          title: _ctx.alert.title,
          message: _ctx.alert.message,
          buttonLabel: _ctx.alert.buttonLabel,
          link: _ctx.alert.link,
          linkText: _ctx.alert.linkText,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setAlert(null)))
        }, null, 8, ["title", "message", "buttonLabel", "link", "linkText"]))
      : _createCommentVNode("", true)
  ]))
}